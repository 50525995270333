<template>
    <section class="live">
        <div class="container">
            <div class="col-off-1-12 col-10-12">
                <h1>Acompanhe <strong>ao vivo</strong></h1>
            </div>
        </div>

        <div class="container">
            <div class="xcol-off-1-12 col-12-12">
                <carousel
                    id="carousel"
                    :per-page="1"
                    :navigate-to="0"
                    :scrollPerPage="false"
                    :centerMode="true"
                    :autoplay="false"
                    :loop="true"
                    :navigationEnabled="true"
                    :paginationEnabled="true"
                    navigationNextLabel="<i class='icon-right' />"
                    navigationPrevLabel="<i class='icon-left' />"
                    :mouse-drag="true"
                >
                    <slide v-for="(camera, index) in Array.from({ length: 18 }, (_, i) => i + 1)" :key="index">
                        <div class="video-container">
                            <iframe
                                :src="
                                    `http://45.162.71.57:21011/zm/cgi-bin/zms?scale=100&mode=jpeg&maxfps=30&monitor=${camera}&user=ver.pmbc&pass=bdL3%2B1%24FyiDAX%28%7Dc&`
                                "
                                frameborder="0"
                                allowfullscreen
                            ></iframe>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data: function () {
        return {
            cameras: [
                 {
                    slug: "pontal-norte",
                    id: 550604,
                },
                {
                    slug: "atlantica_640_norte",
                    id: 599204,
                },
                {
                    slug: "atlantica_640_sul",
                    id: 599208,
                },
                {
                    slug: "atlantica_1310_norte",
                    id: 600621,
                },
                {
                    slug: "atlantica_1576_norte",
                    id: 609496,
                },

                {
                    slug: "atlantica_1310_sul",
                    id: 600623,
                },

                {
                    slug: "atlantica_1576_sul",
                    id: 609498,
                },

                {
                    slug: "atlantica_alm_tamand_norte",
                    id: 600625,
                },
                {
                    slug: "atlantica_alm_tamand_sul",
                    id: 600626,
                },
                {
                    slug: "atlantica_2390_norte",
                    id: 609491,
                },
                {
                    slug: "atlantica_2390_sul",
                    id: 609493,
                },
                {
                    slug: "atlantica_3030_sul",
                    id: 600629,
                },
                {
                    slug: "atlantica_3030_norte",
                    id: 600627,
                },
                {
                    slug: "atlantica_4144_norte",
                    id: 600864,
                },

                {
                    slug: "barra_sul",
                    id: 607911,
                },
                {
                    slug: "atlantica_4740_norte",
                    id: 607621,
                },
                {
                    slug: "atlantica_4740_sul",
                    id: 607620,
                },
                {
                    slug: "atlantica_5443_norte",
                    id: 600860,
                },
                {
                    slug: "atlantica_5443_sul",
                    id: 600863,
                },
            ],
        };
    },

    mounted: function () {
        setInterval(function () {
            var videoAtivo = document.querySelector(
                ".VueCarousel-inner > div.VueCarousel-slide-active iframe"
            );

            if (!videoAtivo) {
                console.log("NENHUM SELECIONADO");

                videoAtivo = document.querySelectorAll(
                    ".VueCarousel-inner > div iframe"
                )[0];
            }

            if (!videoAtivo.src) {
                videoAtivo.setAttribute(
                    "src",
                    videoAtivo.getAttribute("srcbkp")
                );
            }

            let videos = document.querySelectorAll(
                ".VueCarousel-inner > div:not(.VueCarousel-slide-active) iframe"
            );

            videos.forEach(function (iframe) {
                if (iframe.src != "" && iframe.src != videoAtivo.src) {
                    iframe.setAttribute("srcbkp", iframe.src);
                    iframe.removeAttribute("src");
                }
            });
        }, 500);
    },
};
</script>