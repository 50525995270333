import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

//Importa rotas
import routes from "./routes"
const router = new Router({ 
    mode: 'history',
    base: '',
    routes,
    scrollBehavior() {
        return {x: 0, y: 0}
    }
})

export default router