import { render, staticRenderFns } from "./linha-do-tempo.vue?vue&type=template&id=e02241fa&"
import script from "./linha-do-tempo.vue?vue&type=script&lang=js&"
export * from "./linha-do-tempo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports