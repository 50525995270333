<template>
    <section id="contato">
        
        <div class="container">
            <div class="cols">
                <div class="col-1-1">
                    <img src="@theme/assets/images/hr2.png" class="hr" />
                </div>
            </div>
        </div>

        <div class="container">
            <div class="cols">
                <div class="col-1-1">
                    <h1><strong>Fale</strong> Conosco</h1>
                </div>
            </div>
        </div>

         <div class="container">
             <form @submit.prevent="enviar">
            <div class="cols">
                <div class="col-4-10 col-off-1-10">
                <div class="coluna">
                    <input type="text" name="nome" placeholder="Nome" required v-model="nome" :readonly="loading" />
                    <input type="email" name="email" placeholder="E-mail" required v-model="email" :readonly="loading" >
                    <input type="tel" name="telefone" placeholder="Telefone" required v-model="fone" :readonly="loading" />
                    <input type="text" name="assunto" placeholder="Assunto" required v-model="assunto" :readonly="loading" />
                </div>
                    
                </div>
                <div class="col-4-10">

                    <div class="coluna">
                        <textarea name="mensagem" placeholder="Mensagem" v-model="mensagem" required :readonly="loading" />

                        <button type="submit" :readonly="loading" >{{ loading? 'Enviando...' : 'Enviar Mensagem'}}</button>
                    </div>

                </div>
            </div>
            </form>
        </div>

    </section>
</template>

<script>
export default {

     data: function () {
        return {
           nome: null,
           fone: null,
           email: null,
           assunto: null,
           mensagem: null,
           loading: false
        }
    },
    
    methods: {
       enviar: function(e){
           var $this = this;
             e.preventDefault();
             this.loading = true;
           
            this.$sdk.endpoint = 'https://app.taticadesucesso.com.br/sendmail/';
            this.$sdk.post('sendmail.php', { 
                remetente: 'sendmail@tihhgoncalves.com',
                remetente_nome: 'novapraiacentral.com.br',
                destinatario: 'marketing@bc.sc.gov.br',
                assunto: this.assunto,
                site: 'www.novapraiacentral.com.br',
                nome: this.nome, 
                email: this.email,  
                msg: this.mensagem, 
                telefone: this.fone,
            }, function(res){
                $this.loading = false;
                console.log(res);
                $this.nome = null;
                $this.email = null;
                $this.mensagem = null;
                $this.fone = null;
                $this.assunto = null;

                $this.$swal('Enviado!', 'Sua mensagem foi enviada com sucesso.', 'OK');
            })
        },
    },

}
</script>