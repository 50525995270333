<template>
    <section class="faq">
        <div class="container">

            <div class="col-1-1">
                <h1><strong>Dúvidas</strong> Frequentes</h1>
            </div>
        </div>
        <div class="container">

            <div class="cols">

                <div class="col-1-2 pergunta" v-for="(pergunta, index) in perguntas" :key="index">

                    <div class="box">
                        <div class="top" @click="click(pergunta)">
                            <div class="num">{{ index+1 }}.</div>
                            <img src="@theme/assets/images/seta-alto.png" class="seta" v-show="pergunta.status" />
                            <img src="@theme/assets/images/seta-baixo.png" class="seta" v-show="!pergunta.status" />
                            <div class="txt">{{ pergunta.Pergunta }}</div>
                        </div>
                        <div class="resp" v-show="pergunta.status" v-html="pergunta.Resposta">
                        </div>
                        
                    </div>
                    
                </div>

            </div>
            
        </div>
    </section>    
</template>


<script>
export default {
    data: function () {
        return {
           perguntas: [],
        }
    },

    methods: {

        click(pergunta){

            pergunta.status = !pergunta.status;

        }

    },

    mounted: function(){

        var $this = this;

        $this.$sdk.get('faq', function(data){

            console.log('API - Carregando Perguntas do FAQ');
            
            data.forEach( function(item) {

                item['status'] = false;

            
                $this.perguntas.push(item);

            });
        
        })

    }

}
</script>