import Vue          from 'vue'
import axios        from 'axios'
import VueAxios     from 'vue-axios'
Vue.use(VueAxios, axios)



export default {

    endpoint : 'http://admin.novapraiacentral.com.br/api/',
    prefix_images: 'http://admin.novapraiacentral.com.br/uploads/',
    parametros: [],
    
    post(uri, params, callback){
        var url = this.endpoint + uri;

        console.log('[API POST]', url);

        Vue.axios.post(url, params).then((response) => {
            console.log('[API RESPONSE]', response.data);
            callback(response.data);
        })
    },

    get(uri, callback, id){

        var url = this.endpoint + uri;

        if(id){
            url += '?id=' + id;
        }

        console.log('[API GET]', url);

        Vue.axios.get(url).then((response) => {
            console.log('[API RESPONSE]', response.data);
            callback(response.data);
        })

        

    },

    prepare(){

        var $this = this;
        this.get('parametros', function(data){
            console.log('[data]', data)
            $this.parametros = data;
        })
    },

    parametro(id, callback){

        var $this = this;

        if($this.parametros.length == 0){

            setTimeout(function(){
                $this.parametro(id, callback);
            }, 100)

        } else {

            console.log('[parametro]', $this.parametros);

            $this.parametros.forEach(function(param){
                if(param.Identificador == id)
                    callback(param.Valor);
            });

        }


    },

   
    /**
     * Instalar use no Vue.
     * 
     * @param {Vue} Instancia do Vue
     */
    install(Vue) {
        Vue.prototype.$sdk = this;
        Vue.sdk = this;

        this.prepare();
    }
};