<template>
    <div>
        <s-cabecalho />

        <section id="downloads">
            <div class="container">

                <div class="cols">
                    <div class="col-1-1">
                        <h1>Downloads</h1>
                    </div>
                </div>

            </div>
            <div class="container">

                <div class="cols">
                    <div class="col-10-12 col-off-1-12">

                        <table id="downloads" style="border-collapse: collapse; width: 100%;" border="1">
                            <thead>
                                <tr>
                                    <td style="width: 25%;">Nome do arquivo</td>
                                    <td style="width: 25%;">Descrição</td>
                                    <td style="width: 25%;">Download</td>
                                </tr>
                            </thead>
                            <tbody>

                                <tr v-for="(arquivo, index) in arquivos" :key="index">
                                    <td style="width: 25%;">{{ arquivo.titulo }}</td>
                                    <td style="width: 25%;">{{ arquivo.descricao }}</td>
                                    <td style="width: 25%;">
                                        <a :href="arquivo.link">Clique aqui para baixar</a>
                                    </td>
                                </tr>
                                
                            </tbody>
                        </table>

                    </div>
                </div>

            </div>
        </section>
        
        <s-rodape />
        
    </div>
</template>
<script>
export default {

    data: function () {
        return {
            arquivos: [
                {
                    titulo: 'Plano Municipal de Cultura Oceânica de Balneário Camboriú',
                    descricao: 'Informativo sobre o PLANO MUNICIPAL DE CULTURA OCEÂNICA de Balneário Camboriú, instituído pelo Decreto Municipal Nº 10.823/202.',
                    link: 'http://downloads.novapraiacentral.com.br/?arquivo=plano-municipal-de-cultura-oceanica-de-balneario-camboriu.pdf'
                },                
                {
                    titulo: 'Bolsões de água e escarpas',
                    descricao: 'Card informativo sobre os bolsões de água e escarpas na Praia Central de Balneário Camboriú.',
                    link: 'http://downloads.novapraiacentral.com.br/?arquivo=bolsoes-de-agua-escarpas.pdf'
                },
                {
                    titulo: 'Apresentação PEA',
                    descricao: 'Conhecendo a obra e os cuidados ambientais.',
                    link: 'http://downloads.novapraiacentral.com.br/?arquivo=apresentacao-pea.pdf'
                },
                {
                    titulo: 'Folder Nova Praia Central',
                    descricao: 'Etapas da obra e cuidados ambientais.',
                    link: 'http://downloads.novapraiacentral.com.br/?arquivo=folder-da-faixa-de-areia-da-praia-central.pdf'
                },
                {
                    titulo: 'Vídeos Etapas da Obra',
                    descricao: 'Compilado de vídeos com as etapas da obra de alargamento da faixa de areia da praia central.',
                    link: 'http://downloads.novapraiacentral.com.br/?arquivo=videos-do-bira.mp4'
                },
                {
                    titulo: 'Informativo da Diversidade Marinha na Enseada de Balneário Camboriú',
                    descricao: 'Conheça um pouco mais sobre a diversidade marinha na nossa cidade.',
                    link: 'http://downloads.novapraiacentral.com.br/?arquivo=informativo-fauna-marinha-pmbc.pdf'
                },
                {
                    titulo: 'Ecossistema de Dunas e Restingas',
                    descricao: 'Conheça as possíveis espécies de vegetação de restinga que serão plantadas para compor o sistema de dunas embrionária da Nova Praia Central de Balneário Camboriú.',
                    link: 'http://downloads.novapraiacentral.com.br/?arquivo=ecossistema-de-dunas-e-restingas.pdf'
                },
                {
                    titulo: 'Programa Escola Azul',
                    descricao: 'O Programa Escola Azul como ação das atividades do Programa de Educação Ambiental (PEA) da Obra de Recuperação da Faixa de Areia da praia de Balneário Camboriú.',
                    link: 'http://downloads.novapraiacentral.com.br/?arquivo=programa-escola-azul-em-balneario-camboriu.pdf'
                }

            ]
        }
    }
        
}
</script>