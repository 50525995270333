<template>
    <section id="infografico">

        <div class="container">
            <div class="cols">
                <div class="col-1-1">
                    <h1><strong>Infográfico</strong></h1>
                </div>
            </div>
            
        </div>

        <div class="container">
            <div class="cols">
                <div class="col-1-1">
                    <div id="tela">

                        <div v-for="(pin, index) in  pins" :key="index" class="pin" :style="pin.style" @click="$modal.show('pin' + (index+1))">
                            <img v-svg-inline class="icon" :src="require('@theme/assets/images/pin' + (index+1) + '.svg')" :title="pin.titulo"/>
                        </div>                                                                                                                                                                                                                       

                        

                    </div>
                </div>
            </div>
        
        </div>

        <modal v-for="(pin, index) in  pins" :key="index" :name="'pin'+(index+1) " :width="800" :height="400">
            <button class="close" @click="$modal.hide('pin' + (index+1) )">x</button>
            <div class="icone">
                <img v-svg-inline class="icon" :src="require('@theme/assets/images/pin'+ (index+1) +'.svg')"/>
            </div>

            <h1>{{ pin.titulo}}</h1>
            <div v-html="pin.texto"></div>
        </modal>

      
        
        
    </section>
</template>
<script>
export default {
    
    data: function () {
        return {
           pins: [
               {

                   titulo: 'Programa Ambiental de Construção (PAC)',
                   texto: '<p><strong>Objetivo: </strong>Coordenar as a&ccedil;&otilde;es ambientais durante a etapa de obras, garantindo a execu&ccedil;&atilde;o das a&ccedil;&otilde;es socioambientais previstas na instala&ccedil;&atilde;o e a inexist&ecirc;ncia de passivos ambientais.</p><p><strong>A&ccedil;&otilde;es: </strong>Acompanhar as obras civis a serem executadas, determinando procedimento para regulariza&ccedil;&atilde;o de n&atilde;o conformidades identificadas; Assegurar a realiza&ccedil;&atilde;o das a&ccedil;&otilde;es previstas pelo &oacute;rg&atilde;o ambiental licenciador; Coordenar a&ccedil;&otilde;es de seguran&ccedil;a e meio ambiente no ambiente da obra.</p><p><strong>Cronograma: </strong>O PAC teve in&iacute;cio ainda no per&iacute;odo pr&eacute;-obras e deve permanecer ativo ao longo de todo o processo de instala&ccedil;&atilde;o, aproximadamente entre fevereiro e dezembro de 2021.</p>',
                   style: 'margin-left: -450px; top: 37px;'
               },
               {

                   titulo: 'Programa de Controle Ambiental da Atividade de Dragagem (PCAAD)',
                   texto: '<p><strong>Objetivo: </strong>Conferir a adequa&ccedil;&atilde;o ambiental do processo de dragagem e evitar acidentes com a fauna marinha.</p><p><strong>A&ccedil;&otilde;es: </strong>Verificar poss&iacute;veis inconformidades ambientais na atividade de dragagem; Avaliar a efic&aacute;cia das a&ccedil;&otilde;es de mitiga&ccedil;&atilde;o e, caso necess&aacute;rio, propor novas medidas; Monitorar a presen&ccedil;a de cet&aacute;ceos (mam&iacute;feros aqu&aacute;ticos) e quel&ocirc;nios (tartarugas), orientando a paralisa&ccedil;&atilde;o da atividade em caso de aproxima&ccedil;&atilde;o.</p><p><strong>Cronograma: </strong>O PCAAD permanecer&aacute; ativo durante todo o processo de dragagem, aproximadamente entre agosto e novembro de 2021.</p>',
                   style: 'margin-left: -218px; top: 45px;'
               },
               {

                   titulo: 'Programa de Monitoramento da Dispersão da Pluma de Sedimentos (PMDPS)',
                   texto: '<p><strong>Objetivo:</strong> Avaliar a movimenta&ccedil;&atilde;o da areia levantada pela dragagem no fundo do mar.</p><p><strong>A&ccedil;&otilde;es: </strong>Medir as concentra&ccedil;&otilde;es dos sedimentos em suspens&atilde;o e outros par&acirc;metros f&iacute;sico-oceanogr&aacute;ficos nas &aacute;reas da jazida e enseada da Praia Central; Imageamento a&eacute;reo com uso de drone para identifica&ccedil;&atilde;o visual da pluma de sedimentos; Propor medidas corretivas, caso constate-se altera&ccedil;&atilde;o dos par&acirc;metros.</p><p><strong>Cronograma: </strong>O PMDPS permanecer&aacute; ativo durante todo o processo de instala&ccedil;&atilde;o, incluindo p&oacute;s-obras, aproximadamente entre mar&ccedil;o e dezembro de 2021.</p>',
                   style: 'margin-left: -270px; top: 162px;'
               },
               {

                   titulo: 'Programa de Emergência Individual (PEI):',
                   texto: '<p><strong>Objetivo</strong>: Prevenir incidentes de polui&ccedil;&atilde;o por &oacute;leo. &Eacute; similar ao PAE, mas voltado para procedimentos de resposta a esse tipo de evento.</p><p><strong>A&ccedil;&otilde;es: </strong>Caracterizar os riscos existentes na &aacute;rea das obras; Descrever os cen&aacute;rios acidentais e as a&ccedil;&otilde;es sugeridas em cada caso; Estabelecer um Sistema de Alerta de Derramamento de &Oacute;leo; Proceder &ndash; em caso de acidente &ndash; com a&ccedil;&otilde;es de corre&ccedil;&atilde;o ambiental (interrup&ccedil;&atilde;o, conten&ccedil;&atilde;o, prote&ccedil;&atilde;o, recolhimento, etc.).</p><p><strong>Cronograma: </strong>O PEI permanecer&aacute; ativo durante todo o processo de instala&ccedil;&atilde;o, aproximadamente entre mar&ccedil;o e dezembro de 2021.</p>',
                   style: 'margin-left: 55px; top: 206px;'
               },
               {

                   titulo: 'Programa de Ação de Emergência (PAE): ',
                   texto: '<p><strong>Objetivo:</strong> Estabelecer e descrever os procedimentos de resposta &agrave;s situa&ccedil;&otilde;es emergenciais (incidentes) que eventualmente possam vir a ocorrer.</p><p><strong>A&ccedil;&otilde;es: </strong>Caracterizar os riscos existentes na &aacute;rea das obras; Estabelecer as a&ccedil;&otilde;es de emerg&ecirc;ncia para cada um dos cen&aacute;rios poss&iacute;veis; Estruturar um sistema de resposta &agrave;s emerg&ecirc;ncias, garantindo a exist&ecirc;ncia desses servi&ccedil;os; Capacitar os colaboradores para as a&ccedil;&otilde;es de emerg&ecirc;ncia; Propor medidas corretivas, caso constate-se risco iminente que n&atilde;o tenha sido previsto.</p><p><strong>Cronograma: </strong>O PAE permanecer&aacute; ativo durante todo o processo de instala&ccedil;&atilde;o, aproximadamente entre mar&ccedil;o e dezembro de 2021.</p>',
                   style: 'margin-left: 152px; top: 328px;'
               },
               {

                   titulo: 'Programa de Mitigação das Interferências no Sistema Viário (PMISV)',
                   texto: '<p><strong>Objetivo</strong>: Controlar o aumento de tr&aacute;fego nas vias de entorno e estabelecer medidas para prevenir que a circula&ccedil;&atilde;o de ve&iacute;culos decorrente das obras comprometa o tr&aacute;fego das vias de entorno.</p><p><strong>A&ccedil;&otilde;es: </strong>Monitorar, por meio de contagem, a evolu&ccedil;&atilde;o do tr&aacute;fego nas principais vias da regi&atilde;o central; Realizar sinaliza&ccedil;&atilde;o complementar das vias de entorno; Propor medidas corretivas, caso constate-se altera&ccedil;&atilde;o significa ou risco iminente.</p><p><strong>Cronograma: </strong>O PMISV ter&aacute; atividades pr&eacute;-dragagem, durante e p&oacute;s-dragagem.</p>',
                   style: 'margin-left: 222px; top: 403px;'
               },
               {

                   titulo: 'Programa de Segurança da Praia (PSP):',
                   texto: '<p><strong>Objetivo</strong>: Garantir medidas de seguran&ccedil;a para preven&ccedil;&atilde;o de acidentes relacionados com as obras, na terra e no mar.</p><p><strong>A&ccedil;&otilde;es</strong>: Sinalizar, na terra e no mar, a presen&ccedil;a das obras e de &aacute;reas restritas; Apoiar a elabora&ccedil;&atilde;o dos programas de preven&ccedil;&atilde;o e remedia&ccedil;&atilde;o de riscos trabalhistas e ambientais realizadas pela equipe de Sa&uacute;de e Seguran&ccedil;a do Trabalho; Auxiliar nas capacita&ccedil;&otilde;es de profissionais e na comunica&ccedil;&atilde;o com transeuntes que possam reduzir os riscos da obra; Propor medidas corretivas, caso constate-se risco n&atilde;o previsto inicialmente.</p><p><strong>Cronograma: </strong>O PSP permanecer&aacute; ativo durante todo o processo de instala&ccedil;&atilde;o, aproximadamente entre mar&ccedil;o e dezembro de 2021.</p>',
                   style: 'margin-left: 365px; top: 308px;'
               },
               {

                   titulo: 'Programa de Monitoramento da Qualidade dos Sedimentos (PMQS): ',
                   texto: '<p><strong>Objetivo</strong>: Avaliar se a areia utilizada na recupera&ccedil;&atilde;o &eacute; adequada para utiliza&ccedil;&atilde;o e se existem poluentes nela.</p><p><strong>A&ccedil;&otilde;es: </strong>Coletar sedimentos em diversos pontos para avalia&ccedil;&atilde;o da granulometria e dos par&acirc;metros qu&iacute;micos e de ecotoxicidade; Propor medidas corretivas, caso constate-se altera&ccedil;&atilde;o significativa da jazida.</p><p><strong>Cronograma: </strong>O PMQS permanecer&aacute; ativo durante todo o processo de dragagem, aproximadamente entre setembro e outubro de 2021.</p>',
                   style: 'margin-left: 100px; top: 100px;'
               },
               {

                   titulo: 'Programa de Acompanhamento da Hidrodinâmica da Jazida (PAHJ)',
                   texto: '<p><strong>Objetivo</strong>: Verificar de que forma a &aacute;rea da jazida se comporta ap&oacute;s a retirada do material.</p><p><strong>A&ccedil;&otilde;es</strong>: Levantar dados (geof&iacute;sicos e magn&eacute;ticos) da &aacute;rea da jazida e acompanhar as altera&ccedil;&otilde;es em suas caracter&iacute;sticas; Instruir, a partir dos dados levantados, a forma mais adequada de realizar a dragagem na jazida; Propor medidas corretivas, caso constate-se altera&ccedil;&atilde;o significativa da jazida.</p><p><strong>Cronograma: </strong>O PAHJ permanecer&aacute; ativo durante todo o processo de instala&ccedil;&atilde;o, aproximadamente entre mar&ccedil;o e dezembro de 2021.</p>',
                   style: 'margin-left: -175px; top: 120px;'
               },
               {

                   titulo: 'Programa de Monitoramento da Avifauna na Faixa Praial (PMAFP):',
                   texto: '<p><strong>Objetivo:</strong> Acompanhar o padr&atilde;o ecol&oacute;gico das aves que utilizam a praia como habitat.</p><p><strong>A&ccedil;&otilde;es:</strong> Observar a abund&acirc;ncia, a diversidade e a din&acirc;mica populacional da avifauna na Praia Central; Encaminhar as aves debilitadas localizadas para os servi&ccedil;os de resgate de animais silvestres; Propor medidas corretivas, caso constatem-se altera&ccedil;&otilde;es ecol&oacute;gicas not&aacute;veis.</p><p><strong>Cronograma</strong>: Conforme solicita&ccedil;&atilde;o do &oacute;rg&atilde;o ambiental e dos estudos ambientais realizados, o programa come&ccedil;a no pr&eacute;-obra e continuar&aacute; monitoramento a avifauna na praia por 24 meses ap&oacute;s a obra.</p>',
                   style: 'margin-left: 290px; top: 135px;'
               },
               {

                   titulo: 'Programa de Monitoramento das Cotas Batimétricas da Enseada (PMCBE):',
                   texto: '<p><strong>Objetivo: </strong>Observar a profundidade do mar pr&oacute;ximo &agrave; praia para verificar se ocorreram mudan&ccedil;as no fundo da enseada.</p><p><strong>A&ccedil;&otilde;es: </strong>Escanear, por meio de um ecobat&iacute;metro de alta resolu&ccedil;&atilde;o, os dados hidrogr&aacute;ficos da enseada antes das obras; Acompanhar, bimestralmente, poss&iacute;veis altera&ccedil;&otilde;es provocadas pelas obras; Verificar, ap&oacute;s as obras, se houve novas altera&ccedil;&otilde;es; Propor medidas corretivas, caso constate-se altera&ccedil;&atilde;o significativa das cotas.</p><p><strong>Cronograma</strong>: Conforme solicita&ccedil;&atilde;o do &oacute;rg&atilde;o ambiental e dos estudos ambientais realizados, o programa come&ccedil;a no pr&eacute;-obra e continuar&aacute; monitorando as cotas batim&eacute;tricas por 12 meses ap&oacute;s a obra.</p>',
                   style: 'margin-left: -40px; top: 350px;'
               },
               {

                   titulo: 'Programa de Monitoramento do Perfil Praial (PMPP)',
                   texto: '<p><strong>Objetivo: </strong>Analisar a linha de costa da praia antes, durante e depois das obras.</p><p><strong>A&ccedil;&otilde;es: </strong>Caracterizar e acompanhar as mudan&ccedil;as no perfil topogr&aacute;fico (obtido por taque&ocirc;metro)<strong>, </strong>durante e ap&oacute;s as obras de recupera&ccedil;&atilde;o da faixa de areia; Mapear e avaliar, por meio de imagens de drone, as mudan&ccedil;as no perfil praial durante e ap&oacute;s as obras; Propor medidas corretivas, caso constate-se altera&ccedil;&atilde;o dos par&acirc;metros.</p><p><strong>Cronograma</strong>: Conforme solicita&ccedil;&atilde;o do &oacute;rg&atilde;o ambiental e dos estudos ambientais realizados, o programa come&ccedil;a no pr&eacute;-obra e continuar&aacute; promovendo a&ccedil;&otilde;es por 24 meses ap&oacute;s a obra.</p>',
                   style: 'margin-left: 395px; top: 190px;'
               },
               {

                   titulo: 'Programa de Acompanhamento do Clima de Ondas e da Dinâmica Praial (PACODP):',
                   texto: '<p><strong>Objetivo: </strong>Investigar poss&iacute;veis impactos da obra na forma&ccedil;&atilde;o de ondas, correntes e mar&eacute;s.</p><p><strong>A&ccedil;&otilde;es:</strong> Instala&ccedil;&atilde;o de um perfilador hidroac&uacute;stico de correntes (ADCP) que acompanhar&aacute; os par&acirc;metros de onda, corrente e mar&eacute; em um ponto na enseada; Propor medidas corretivas, caso constate-se altera&ccedil;&atilde;o dos par&acirc;metros.</p><p><strong>Cronograma</strong>: Conforme solicita&ccedil;&atilde;o do &oacute;rg&atilde;o ambiental e dos estudos ambientais realizados, o programa come&ccedil;a no pr&eacute;-obra e continuar&aacute; monitorando a din&acirc;mica de ondas e praial por 12 meses ap&oacute;s a obra.</p>',
                   style: 'margin-left: -210px; top: 300px;'
               },
               {

                   titulo: 'Programa de Educação Ambiental (PEA)',
                   texto: '<p><strong>Objetivo: </strong>Buscar a sensibiliza&ccedil;&atilde;o da popula&ccedil;&atilde;o e dos trabalhadores para as quest&otilde;es ambientais que envolvem o projeto.</p><p><strong>A&ccedil;&otilde;es: </strong>Di&aacute;logos de Educa&ccedil;&atilde;o Ambiental com os colaboradores da obra; Produ&ccedil;&atilde;o de cartilhas educativas para a rede municipal de ensino, trazendo informa&ccedil;&otilde;es sobre a obra, os programas ambientais, e no&ccedil;&otilde;es b&aacute;sicas sobre a restinga e a din&acirc;mica praial; Capacita&ccedil;&atilde;o de multiplicadores na rede municipal de ensino para utiliza&ccedil;&atilde;o do material e fomento de novas atividades.</p><p><strong>Cronograma</strong>: Conforme solicita&ccedil;&atilde;o do &oacute;rg&atilde;o ambiental e dos estudos ambientais realizados, o programa come&ccedil;a no pr&eacute;-obra e continuar&aacute; promovendo a&ccedil;&otilde;es por 12 meses ap&oacute;s a obra.</p>',
                   style: 'margin-left: 180px; top: 490px;'
               },
               {

                   titulo: 'Programa de Comunicação Social (PCS):',
                   texto: '<p><strong>Objetivo: </strong>Divulgar informa&ccedil;&otilde;es sobre a obra e os programas ambientais realizados, bem como coletar a opini&atilde;o e responder as d&uacute;vidas das pessoas interessadas.</p><p><strong>A&ccedil;&otilde;es: </strong>Distribui&ccedil;&atilde;o de folders com informa&ccedil;&otilde;es sobre o projeto e seus cuidados ambientais; Instala&ccedil;&atilde;o do cont&ecirc;iner de comunica&ccedil;&atilde;o no canteiro de obras; Atualiza&ccedil;&atilde;o das informa&ccedil;&otilde;es sobre as obras no <em>site</em> do projeto; Reuni&otilde;es de articula&ccedil;&atilde;o com a comunica&ccedil;&atilde;o social da prefeitura, potencializando as a&ccedil;&otilde;es de divulga&ccedil;&atilde;o.</p><p><strong>Cronograma</strong>: Conforme solicita&ccedil;&atilde;o do &oacute;rg&atilde;o ambiental e dos estudos ambientais realizados, o programa come&ccedil;a no pr&eacute;-obra e continuar&aacute; promovendo a&ccedil;&otilde;es por 24 meses ap&oacute;s a obra.</p>',
                   style: 'margin-left: 295px; top: 500px;'
               },
               {

                   titulo: 'Programa de Monitoramento da Pesca Artesanal (PMPA)',
                   texto: '<p><strong>Objetivo: </strong>Averiguar poss&iacute;veis efeitos da obra sobre a produ&ccedil;&atilde;o e renda dos pescadores artesanais da regi&atilde;o.</p><p><strong>A&ccedil;&otilde;es:</strong> Reuni&otilde;es de apresenta&ccedil;&atilde;o e de acompanhamento; Caracteriza&ccedil;&atilde;o dos pescadores e da pesca artesanal; Monitoramento dos desembarques antes, durante e depois da dragagem.</p><p><strong>Cronograma: </strong>O PMPA permanecer&aacute; ativo durante todo o processo de instala&ccedil;&atilde;o, aproximadamente entre mar&ccedil;o e dezembro de 2021.</p>',
                   style: 'margin-left: 30px; top: 30px;'
               },
               {

                   titulo: 'Plano de Monitoramento dos Níveis de Pressão Sonora (PMNPS)',
                   texto: '<p><strong>Objetivos</strong>: Auditar os ru&iacute;dos produzidos pelas obras e seu efeito sobre poss&iacute;veis ouvintes no entorno.</p><p><strong>A&ccedil;&otilde;es: </strong>Aferir, quinzenalmente, a press&atilde;o sonora no entorno das obras, conforme normas t&eacute;cnicas da ABNT; Propor medidas corretivas, caso constate-se altera&ccedil;&atilde;o dos par&acirc;metros.</p><p><strong>Cronograma: </strong>O PMQAr permanecer&aacute; ativo durante todo o processo de dragagem, aproximadamente entre setembro e dezembro de 2021.</p>',
                   style: 'margin-left: 275px; top: 360px;'
               },
               {

                   titulo: 'Programa de Monitoramento da Biota Aquática (PMBA)',
                   texto: '<p><strong>Objetivo:</strong> Acompanhar aspectos ecol&oacute;gicos acerca dos diversos seres vivos que vivem nas &aacute;reas aqu&aacute;ticas influenciadas pelo projeto.</p><p><strong>A&ccedil;&otilde;es: </strong>Coletar dados sobre cinco comunidades faun&iacute;sticas (i. Planct&ocirc;nica, ii. Bent&ocirc;nica, iii. Carcinofauna; iv. Ictioplanct&ocirc;nica e v. Cet&aacute;ceos e tartarugas marinhas),; Propor medidas corretivas, caso constate-se altera&ccedil;&atilde;o da estrutura ecol&oacute;gica local.</p><p><strong>Cronograma: </strong>Conforme solicita&ccedil;&atilde;o do &oacute;rg&atilde;o ambiental e dos estudos ambientais realizados, o programa continuar&aacute; monitorando a biota por 24 meses ap&oacute;s a obra.</p>',
                   style: 'margin-left: -375px; top: 240px;'
               },
               {

                   titulo: 'Programa de Monitoramento da Qualidade da Água (PMQA)',
                   texto: '<p><strong>Objetivo:</strong> Verificar a qualidade da &aacute;gua nas &aacute;reas influenciadas diretamente pelo projeto.</p><p><strong>A&ccedil;&otilde;es: </strong>Realizar 10 campanhas de coleta e an&aacute;lise de &aacute;gua em 15 pontos (incluindo &aacute;rea da jazida, enseada da Praia Central e as fozes dos rios Cambori&uacute; e Marambaia); Propor medidas corretivas, caso constate-se altera&ccedil;&atilde;o dos par&acirc;metros.</p><p><strong>Cronograma: </strong>O PMQA permanecer&aacute; ativo durante todo o processo de instala&ccedil;&atilde;o, aproximadamente entre mar&ccedil;o e dezembro de 2021 e continuar&aacute; ap&oacute;s o per&iacute;odo de obra durante 24 meses.</p>',
                   style: 'margin-left: -150px; top: 195px;'
               },
               {

                   titulo: 'Plano de Gerenciamento de Resíduos da Construção Civil (PGRCC)',
                   texto: '<p><strong>Objetivo: </strong>Realizar a gest&atilde;o (desde a segrega&ccedil;&atilde;o at&eacute; o destino final) dos res&iacute;duos gerados diretamente pelas obras civis.</p><p><strong>A&ccedil;&otilde;es: </strong>Classificar, segregar, acondicionar e dar destina&ccedil;&atilde;o final adequada aos res&iacute;duos gerados nas obras; Promover a redu&ccedil;&atilde;o, o reaproveitamento e a reciclagem dos res&iacute;duos s&oacute;lidos.</p><p><strong>Cronograma: </strong>O PGRCC permanecer&aacute; ativo durante todo o processo de instala&ccedil;&atilde;o, aproximadamente entre mar&ccedil;o e dezembro de 2021.</p>',
                   style: 'margin-left: 345px; top: 245px;'
               },
               {

                   titulo: 'Programa de Monitoramento da Qualidade do Ar (PMQAr)',
                   texto: '<p><strong>Objetivos:</strong> Examinar continuamente a qualidade do ar nos locais interferidos pelo projeto.</p><p><strong>A&ccedil;&otilde;es: </strong>Mensurar os par&acirc;metros b&aacute;sicos (CO, NO2, O3 e MP10) em dois pontos da praia para quantifica&ccedil;&atilde;o da qualidade do ar; Propor medidas corretivas, caso constate-se altera&ccedil;&atilde;o dos par&acirc;metros.</p><p><strong>Cronograma: </strong>O PMQAr permanecer&aacute; ativo durante todo o processo de dragagem, aproximadamente entre setembro e outubro de 2021</p>',
                   style: 'margin-left: 65px; top: 445px;'
               }
           ]
        }
    },


}
</script>